/*=================================================================
   _baseline.scss
    ===============================================================*/

html {
  height: 100%;
  font-size: 100%;
}
body {
  height: 100%;
  color: $shade;
  font-family: $base-font-family;
  font-size: 1rem;
  line-height: $global-lineheight-unit/$global-type-unit;
  font-weight: $base-font-weight;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

#root {
  height: 100%;
}

svg {
  fill: currentColor;
}
img {
  max-width: 100%;
}
a {
  color: $blue-accent;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: darken($blue-accent, 6%);
  }
  &:focus {
    outline: 1px solid transparentize($blue, 0.2);
    outline-offset: 1px;
    border-radius: 3px;
    //box-shadow: 0 0 0 4px transparentize($blue-accent, .8);
  }
}
p {
  margin: 0 0 $spacing-md 0;
}
