/*  HACK for Dev mode
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body > iframe:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

li {
  cursor: pointer;
}

.App {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  font-family: "Noto Sans", sans-serif;
  overflow-y: hidden;
}

.inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 89em;
}

.closeModal {
  float: right;
  color: grey;
}

.AppRow {
  display: flex;
  overflow-y: hidden;
}

.PageContainer {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.Checklist {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  color: #4c4c57;
  margin: 0px 5px 0px 5px;
  margin: 1em;
}

.Checklist_inner {
  border: 1px solid #c9cad2;
  border-radius: 0.2em;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.05),
    0 3px 6px 0 rgba(0, 0, 0, 0.05);
  padding: 0.5em;
}

.Checklist_title {
  display: flex;
  margin: 0px;
  padding: 10px;
  -webkit-font-smoothing: antialiased;
  font-family: "Noto Sans", sans-serif;
}

table {
  border-collapse: collapse;
}

tr {
  /* border-bottom: solid 0.5px #dedede; */
}

td {
  padding: 0.5em;
}

/* tr.Checklist_row {
}

td.Checklist_cell {
  border: 0px;
  padding:0px;
  border-top: solid 0.5px #4c4c57;
}

td.Checklist_cell_count {
  border: 0px;
  padding:0px;
  border-top: solid 0.5px #4c4c57;
} */

.settings_button {
  display: flex;
  text-align: left;
  width: 100%;
  margin-left: 1em;
}

.end_session_button {
  background: #ec7800;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 0.5em;
  color: white;
  font-weight: 100;
  text-align: center;
  vertical-align: middle;
}

.session_analysis_modal_div {
  text-align: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
}

.session_analysis {
  margin: 0 auto;
  background: white;
  -webkit-font-smoothing: antialiased;
  font-family: "Noto Sans", sans-serif;
  width: 85%;
}

.donut_field {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.donut {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
  display: inline;
  vertical-align: top;
  padding: 20px;
}

.donut_svg {
  width: 100%;
}

.donut_text {
  vertical-align: top;
  text-align: center;
  align-self: center;
  width: 100%;

  -webkit-font-smoothing: antialiased;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 1em;
  font-size: 1.3rem;
}

.percent_bar_field {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.percent_bar {
  display: flex;
  margin: 5px 20px;
}

.percent_bar_icon_div {
}

.percent_bar_div {
  margin: 0px 5px;
  width: 250px;
  margin-bottom: 1em;
}

.percent_bar_div2 {
  margin: 0px;
  padding: 0px;
}

.percent_bar_text {
  font-size: 1.3rem;
  text-align: left;
}
