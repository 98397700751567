// Brand colours

$orange-text: #8f4800;
$orange-darker: #773c00;
$orange-dark: #bf6000;
$orange: #ef7900;
$orange-light: #f5ae66;
$orange-lighter: #fbe4cc;

$blue-text: #033d5d;
$blue-darker: #023957;
$blue-dark: #04527c;
$blue: #06679b;
$blue-light: #69a3c3;
$blue-lighter: #cde0eb;
$blue-lightest: #ebf4fa;
$blue-accent: #0293e1;

$teal-text: #256c5b;
$teal-darker: #1f594b;
$teal-dark: #329079;
$teal: #3fb498;
$teal-light: #8bd2c1;
$teal-lighter: #d8f0ea;

$purple-text: #642e5a;
$purple-darker: #6b3060;
$purple-dark: #863d78;
$purple: #a84d97;
$purple-light: #ca94c0;
$purple-lighter: #eddbea;

// Mist and shade

$mist-dark: #c3ced6;
$mist: #e1e4e8;
$mist-light: #f2f5f7;
$mist-lighter: #f7f9fa;

$shade: #202b36;
$shade-light: #484e5c;
$shade-lighter: #687382;
$shade-lightest: #959dab;

// UI colours

$red-text: #593c38;
$red-darker: #330303;
$red-dark: #bf260b;
$red: #de3618;
$red-light: #fa9c8c;
$red-lighter: #faebe6;

$yellow-text: #595132;
$yellow-darker: #573a05;
$yellow-dark: #9c6e24;
$yellow: #edc10e;
$yellow-light: #ffea8f;
$yellow-lighter: #fcf1c7;

$green-text: #424f40;
$green-darker: #1a362e;
$green-dark: #1b804b;
$green: #55b846;
$green-light: #bbe5b3;
$green-lighter: #e3f1df;

// Grayscale
$gray-10: #1a1a1a;
$gray-20: #333333;
$gray-30: #4d4d4d;
$gray-45: #727272;
$gray-60: #999999;
$gray-70: #b3b3b3;
$gray-80: #d6d6d6;
$gray-90: #e5e5e5;
$white: #ffffff;
