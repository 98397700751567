// typography defaults - used by the baseline typography.scss file - but made available to all

//what is normal anyway? Varies from typeface to typeface - so abstracted as variables here

$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-light: 200;

$base-font-family: tablet-gothic-wide, sans-serif;
$base-font-rem: 1rem;
$base-font-color: $shade;
$base-font-weight: $font-weight-normal;

$font-size-22: 22 / $global-type-unit * 1rem;
$font-size-18: 18 / $global-type-unit * 1rem;
$font-size-16: 16 / $global-type-unit * 1rem;
$font-size-15: 15 / $global-type-unit * 1rem;
$font-size-14: 14 / $global-type-unit * 1rem;
$font-size-13: 13 / $global-type-unit * 1rem;
$font-size-12: 12 / $global-type-unit * 1rem;

$line-height-22: $global-lineheight-unit / 22;
$line-height-18: $global-lineheight-unit / 18;
$line-height-15: $global-lineheight-unit / 15;
$line-height-16: $global-lineheight-unit / 16;
$line-height-14: $global-lineheight-unit / 14;
$line-height-13: (($global-lineheight-unit/2) * 2) / 13;
$line-height-12: (($global-lineheight-unit/3) * 2) / 12;

$uppercase-letter-spacing: 0.3px;
