// spacing units based on the global rhythm defined as $global-spacing-unit

$sizes: (
  xs: $global-spacing-unit/2,
  sm: $global-spacing-unit,
  md: $global-spacing-unit * 2,
  lg: $global-spacing-unit * 3,
  xl: $global-spacing-unit * 4
);

$spacing-xs: (map-get($sizes, "xs") / $global-type-unit) * 1rem;
$spacing-sm: (map-get($sizes, "sm") / $global-type-unit) * 1rem;
$spacing-md: (map-get($sizes, "md") / $global-type-unit) * 1rem;
$spacing-lg: (map-get($sizes, "lg") / $global-type-unit) * 1rem;
$spacing-xl: (map-get($sizes, "xl") / $global-type-unit) * 1rem;

$spacing-xs-withborder: ((map-get($sizes, "xs")-1) / $global-type-unit) * 1rem;
$spacing-sm-withborder: ((map-get($sizes, "sm")-1) / $global-type-unit) * 1rem;
$spacing-md-withborder: ((map-get($sizes, "md")-1) / $global-type-unit) * 1rem;
$spacing-lg-withborder: ((map-get($sizes, "lg")-1) / $global-type-unit) * 1rem;
$spacing-xl-withborder: ((map-get($sizes, "xl")-1) / $global-type-unit) * 1rem;
