@import "shared/variables/variables";
@import "shared/functions/functions";

$_background: $mist-light;
$_animation-color: $orange;
$_animation-dot: 7;
$_animation-dot-gap: 3;

.fullscreen {
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $_background;
}

.animation {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: rem(44);
  height: rem(44);
}
.speechBubble {
  position: absolute;
  top: 0;
  left: 0;
  width: rem(44);
  height: rem(44);
}
.animationDots,
.animationDots:before,
.animationDots:after {
  display: inline-block;
  width: rem($_animation-dot);
  height: rem($_animation-dot);
  border-radius: 50%;
  background: $_animation-color;
  position: relative;
  animation: blinkingDots 0.9s ease-in-out infinite;
}
.animationDots {
  margin: rem(14) rem($_animation-dot + $_animation-dot-gap) 0;
  animation-delay: 0.3s;
}
.animationDots:before,
.animationDots:after {
  content: "";
  position: absolute;
  right: calc(100% + #{rem($_animation-dot-gap)});
  top: 0;
}
.animationDots:after {
  left: calc(100% + #{rem($_animation-dot-gap)});
  animation-delay: 0.6s;
}

.message {
  font-size: $font-size-13;
  line-height: $line-height-13;
  color: $shade-light;
  margin-top: $spacing-sm;
}

@keyframes blinkingDots {
  50% {
    background: transparentize($_animation-color, 0.6);
  }
}
